package com.stevdza.san.styles

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.JustifySelf
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TransitionProperty
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*

@OptIn(ExperimentalComposeWebApi::class)
val AboutImageStyle by ComponentStyle {
    base {
        Modifier
            .borderRadius(r = 0.px)
            .rotate(0.deg)
            .transition(CSSTransition(property = TransitionProperty.All, duration = 200.ms))

    }
    hover {
        Modifier
            .borderRadius(r = 100.px)
            .rotate(10.deg)
    }
}

val AboutTextStyle by ComponentStyle {
    base {
        Modifier
            .opacity(90.percent)
            .justifySelf(JustifySelf.Auto)
            .transition(CSSTransition(property = "opacity", duration = 200.ms))
            .textAlign(TextAlign.Justify)
    }
    hover {
        Modifier.opacity(100.percent)
    }
}