package com.stevdza.san.sections

import androidx.compose.runtime.*
import com.stevdza.san.components.Header
import com.stevdza.san.components.SocialBar
import com.stevdza.san.models.Section
import com.stevdza.san.models.Theme
import com.stevdza.san.styles.BeautifulInputStyle
import com.stevdza.san.styles.MainButtonStyle
import com.stevdza.san.styles.MainImageStyle
import com.stevdza.san.styles.PrivateSaleButtonStyle
import com.stevdza.san.util.Constants.FONT_FAMILY
import com.stevdza.san.util.Constants.LOREM_IPSUM_SHORTEST
import com.stevdza.san.util.Constants.SECTION_WIDTH
import com.stevdza.san.util.GlobalStuffs
import com.stevdza.san.util.Res
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowRight
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.FaCopy
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.clipboard.Clipboard

@Composable
fun MainSection(onMenuClicked: () -> Unit) {
    Box(
        modifier = Modifier
            .id(Section.Home.id)
            .maxWidth(SECTION_WIDTH.px),
        contentAlignment = Alignment.TopCenter
    ) {
        MainBackground()
        MainContent(onMenuClicked = onMenuClicked)

    }
}
@Composable
fun privateSales() {
    val address = "0x43779fef94c63420855b27084d19b5ee1d164d3c"
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()

    Surface(modifier = Modifier
        .width(
            if (breakpoint >= Breakpoint.MD) 40.percent
            else 90.percent
        )
        .height(400.px)
        .padding(16.px)
        .backgroundColor(Colors.White.copy(alpha = 50))
        .borderRadius(8.px)
        .margin(top = 10.px)){
        Column(horizontalAlignment = Alignment.CenterHorizontally, modifier = Modifier.fillMaxWidth()) {
            H3 (attrs = Modifier
                .color(Colors.White)
                .fillMaxWidth()
                .textAlign(TextAlign.Center)
                .toAttrs()){ Text("Join Our Private Sales !") }

           P(attrs = Modifier.color(Colors.White).toAttrs()) {Text("Make Payments to this Wallet Address:")}
H6  (attrs = Modifier.textOverflow(TextOverflow.Ellipsis).color(Colors.White).toAttrs()){ Row {
    Text(address)
    FaCopy(modifier = Modifier
        .padding(left = 4.px,right = 4.px)
        .cursor(Cursor.Pointer)
        .onClick {
            scope.launch {
            copyToClipboard(address)

        }

        })
} }
           H5(attrs = Modifier.margin(top = 20.px).color(Colors.White).toAttrs()) {  Text("Submit your payment hash below:") }
            Row {
                Input(type = InputType.Text, attrs = BeautifulInputStyle.toAttrs())

Button(attrs = PrivateSaleButtonStyle.toModifier()
    .height(40.px)
    .margin(left = 8.px)
    .border(width = 2.px, LineStyle.Solid,Color.white)
    .borderRadius(r = 5.px)
    .color(Colors.White)
    .cursor(Cursor.Pointer)
    .toAttrs()) { Text("Submit")
FaArrowRight(modifier = Modifier.padding(left = 4.px))
}

            }
        }

    }
}

 fun copyToClipboard(text: String) {


}

@Composable
fun MainBackground() {
    Image(
        modifier = Modifier
            .fillMaxSize()
            .objectFit(ObjectFit.Cover),
        src = Res.Image.background,
        desc = "Background Image"
    )
}

@Composable
fun MainContent(onMenuClicked: () -> Unit) {
    var privatesaleboxvisibility by remember { mutableStateOf(false) }
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Header(onMenuClicked = onMenuClicked) { privatesaleboxvisibility = !privatesaleboxvisibility }
        when{
            privatesaleboxvisibility -> {
                privateSales()
            }
        }

        Column(
            modifier = Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SimpleGrid(
                modifier = Modifier.fillMaxWidth(
                    if (breakpoint >= Breakpoint.MD) 80.percent
                    else 90.percent
                ),
                numColumns = numColumns(base = 1, md = 2)
            ) {
                MainText(breakpoint = breakpoint)
                MainImage()
            }
        }
    }
}

@Composable
fun MainText(breakpoint: Breakpoint) {
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        if (breakpoint > Breakpoint.MD) {
//            SocialBar()
        }
        Column {
            P(
                attrs = Modifier
                    .margin(topBottom = 0.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(if(breakpoint >= Breakpoint.LG) 55.px else 20.px)
                    .fontWeight(FontWeight.Normal)
                    .color(Color.white)
                    .toAttrs()
            ) {
                Text("Welcome to")
            }
            P(
                attrs = Modifier
                    .margin(top = 0.px, bottom = 0.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(if(breakpoint >= Breakpoint.LG) 78.px else 40.px)
                    .fontWeight(FontWeight.Bolder)
                    .color(Color.white)
                    .toAttrs()
            ) {
                Text("SOLGECKO (SLG)")
            }
            P(
                attrs = Modifier
                    .margin(top = 10.px, bottom = 0.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(20.px)
                    .fontWeight(FontWeight.Bold)
                    .color(Color.white)
                    .toAttrs()
            ) {
                Text("Solana's Revolutionary Duo")
            }
            P(
                attrs = Modifier
                    .margin(bottom = 25.px)
                    .maxWidth(400.px)
                    .fontFamily(FONT_FAMILY)
                    .fontSize(15.px)
                    .fontStyle(FontStyle.Italic)
                    .fontWeight(FontWeight.Normal)
                    .color(Color.white)
                    .toAttrs()
            ) {
                Text("Merging AI & Blockchain on Solana | Revolutionizing Crypto with Intelligent Solutions")
            }
            Button(
                attrs = MainButtonStyle.toModifier()
                    .height(40.px)
                    .border(width = 2.px, LineStyle.Solid,Color.white)
                    .borderRadius(r = 5.px)
                    .backgroundColor(Color.transparent)
                    .color(Colors.White)
                    .cursor(Cursor.Pointer)
                    .toAttrs()
            ) {
                Link(
                    modifier = Modifier
                        .color(Colors.White)
                        .textDecorationLine(TextDecorationLine.None),
                    text = "Whitepaper",
                    path = "whitepaper.pdf",
                    openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
                    openInternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB,
                    autoPrefix = true
                )
            }
        }
    }
}

@Composable
fun MainImage() {
    Column(
        modifier = Modifier.fillMaxSize().fillMaxHeight().fillMaxWidth(),
        verticalArrangement = Arrangement.Bottom
    ) {
        Image(
            modifier = MainImageStyle.toModifier().fillMaxWidth(),
            src = Res.Image.main,
            desc = "Main Image"
        )
    }
}
