package com.stevdza.san.models

import com.stevdza.san.util.Constants.LOREM_IPSUM_LONG

enum class Experience(
    val number: String,
    val maintitle: String,
    val description: String,
    val phase: String,
    val subtitle: String
) {
    //phase 1
    //private sale
    //airdrop
    //launc and dex

    //phase 2 ends at wallet listing
    //phase 3 stars banner ads
    //phase4 begin at slg market place
    First(
        number = "01",
        maintitle = "Private Sales",
        description = "Embark on our journey with Solgecko through our exclusive Private Sales phase. Join a select group of early supporters and secure your stake in the groundbreaking SLG token. Experience the power of being at the forefront of a revolutionary financial ecosystem.",
        phase = "Phase 1",
       subtitle = "Igniting Exclusivity"
    ),
    Second(
        number = "02",
        maintitle = "Airdrop",
        description = "Witness the Solgecko revolution unfold as we initiate an exhilarating Airdrop event. Catch the crypto winds and let the tokens rain down. Seize your share of Solgeko's promise as we distribute SLG tokens to our community, fostering widespread participation and inclusivity.",
        phase = "Phase 1",
      subtitle ="Skyfall of Prosperity"
    ),
    Third(
        number = "03",
        maintitle = "Launch and Dex Listing",
        description = "The grand moment is here! Solgecko takes flight with an official Launch and DEX Listing. Embrace the thrill as SLG tokens make their debut on decentralized exchanges, marking the commencement of a new era in decentralized finance. Join the excitement and be part of the Solgeko liftoff.",
        phase = "Phase 1",
subtitle = "Lift-Off to New Horizons"
    ),
    Fourth(
        number = "04",
        maintitle = "Coingecko & Coinmarket",
        description = "Solgecko's trajectory reaches new heights with listings on CoinGecko and CoinMarketCap. Explore the vast cosmos of cryptocurrency as SLG tokens become part of the larger financial galaxy. Track our ascent on these prominent platforms and witness Solgeko's presence in the ever-expanding crypto universe.",
        phase = "Phase 2",
        subtitle = "Navigating the Crypto Cosmos"
    ),
    Fifth(
        number = "05",
        maintitle = "Decentralised Wallet listing",
        description = "Empower your Solgecko holdings with the integration of decentralized wallet listings. Immerse yourself in the security and autonomy of decentralized finance as SLG tokens find their place in leading decentralized wallets. Take control of your assets and experience financial liberation with Solgeko",
        phase = "Phase 2",
        subtitle = "Empowering Your Hold"
    ),
    Sixth(
        number = "06",
        maintitle = "Banner Ads Campaign",
        description = "Embark on a visual journey as Solgecko spreads its wings through a captivating Banner Ads Campaign. Illuminate the digital landscape with the brilliance of SLG, reaching audiences far and wide. Join us in showcasing the revolutionary potential of Solgeko as we transcend boundaries and redefine the future of finance.",
        phase = "Phase 3",
       subtitle = "Unveiling Solgecko: Beyond Borders"
    ),

    Seventh(
        number = "07",
        maintitle = "SLG Merch Store",
        description = "Step into the Solgecko lifestyle with our exclusive SLG Merch Store. Express your commitment to the decentralized future by donning Solgeko-branded merchandise. From stylish apparel to innovative accessories, join the Solgeko community spirit and make a statement that goes beyond the digital realm.",
        phase = "Phase 3",
        subtitle = "Wear the Future, Share the Vision"
    ),
    Eighth(
        number = "08",
        maintitle = "Staking",
        description = "Cultivate your Solgecko holdings by participating in our Staking program. Become a part of the ecosystem's growth as you lock and earn SLG tokens through our secure and rewarding staking mechanism. Nurture your investment and reap the benefits, contributing to the flourishing landscape of decentralized finance with Solgeko",
        phase = "Phase 3",
        subtitle = "Grow Your SLG Garden"
    ),
    Ninth(
        number = "09",
        maintitle = "SLG marketplace",
        description = "Dive into the vibrant Solgecko ecosystem with the launch of our SLG Marketplace. Experience the fusion of utility and innovation as the marketplace becomes a hub for trading, exploring, and flourishing within the Solgeko community. Unleash the full potential of your SLG tokens and explore a world where decentralized commerce meets limitless possibilities.",
        phase = "Phase 4",
        subtitle = "Trade, Explore, Flourish"
    ),
    Tenth(
        number = "10",
        maintitle = "SLG AI Engine",
        description =  "Welcome to the future of finance with the introduction of the Solgecko AI Engine. Revolutionize your transactions as artificial intelligence meets blockchain innovation. Witness a seamless fusion of technology and finance, bringing a new era of efficiency and intelligence to the Solgeko ecosystem. Experience the power of informed decisions with SLG AI.",
        phase = "Phase 4",
        subtitle = "Empowering Transactions with Intelligence"
    )
}