package com.stevdza.san.sections

import androidx.compose.runtime.*
import com.stevdza.san.components.SectionTitle
import com.stevdza.san.components.SkillBar
import com.stevdza.san.models.Section
import com.stevdza.san.models.Skill
import com.stevdza.san.models.Theme
import com.stevdza.san.styles.AboutImageStyle
import com.stevdza.san.styles.AboutTextStyle
import com.stevdza.san.util.*
import com.stevdza.san.util.Constants.FONT_FAMILY
import com.stevdza.san.util.Constants.LOREM_IPSUM_SHORT
import com.stevdza.san.util.Constants.SECTION_WIDTH
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun AboutSection() {
    Box(
        modifier = Modifier
            .id(Section.About.id)
            .maxWidth(SECTION_WIDTH.px)
            .backgroundColor(Theme.LighterGray.rgb)
            .padding(topBottom = 0.px),

        contentAlignment = Alignment.Center
    ) {
PlainBackgroundsecond()
        AboutContent()
    }
}

@Composable
fun AboutContent() {
    val breakpoint = rememberBreakpoint()
    Column(
        modifier = Modifier
            .fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 100.percent
                else 90.percent
            )
            .maxWidth(1200.px),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SimpleGrid(
            modifier = Modifier.fillMaxWidth(
                if (breakpoint >= Breakpoint.MD) 90.percent
                else 100.percent
            )
                .padding( if (breakpoint >= Breakpoint.MD) 5.percent
                else 0.percent),
            numColumns = numColumns(base = 1, md = 2)
        ) {


                AboutMe()

        if (breakpoint >= Breakpoint.MD) {
            AboutImage()
        }
        }
    }
}

@Composable
fun AboutImage() {
    Box(
        modifier = Modifier.fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {
        Image(
            modifier = AboutImageStyle.toModifier()
                .fillMaxWidth()
                .padding(left = 10.px),
            src = Res.Image.about,
            desc = "About Image"
        )
    }
}

@Composable
fun AboutMe() {
    val scope = rememberCoroutineScope()
    var viewportEntered by remember { mutableStateOf(false) }
    val animatedPercentage = remember { mutableStateListOf(0, 0, 0, 0, 0) }

    ObserveViewportEntered(
        sectionId = Section.About.id,
        distanceFromTop = 300.0,
        onViewportEntered = {
            viewportEntered = true
            Skill.values().forEach { skill ->
                scope.launch {
                    animateNumbers(
                        number = skill.percentage.value.toInt(),
                        onUpdate = {
                            animatedPercentage[skill.ordinal] = it
                        }
                    )
                }
            }
        }
    )

    Column(
        modifier = Modifier.fillMaxWidth(),
        verticalArrangement = Arrangement.Center
    ) {
        SectionTitle(section = Section.About)
        P(
            attrs = AboutTextStyle.toModifier()
                .margin(topBottom = 25.px)
                .maxWidth(500.px)
                .fontFamily(FONT_FAMILY)
                .fontSize(18.px)
                .fontWeight(FontWeight.Normal)
                .fontStyle(FontStyle.Normal)
                .color(Color.white)
                .toAttrs()
        ) {

            Text("AI has come to stay, the team at SolGecko is dedicated to get involved and hence, the development of the SLG AI Engine. The SLG AI Engine is a cutting-edge artificial intelligence toolkit that will revolutionize how we think about and interact with AI. This ground-breaking project will bring together the latest advances in machine learning, computer vision, and natural language processing and make them available to anyone with a creative vision and the skills to use them. The SLG AI Engine will be designed with a focus on simplicity and ease of use. The modular architecture will allow developers to pick and choose the tools they need to create the perfect AI solution for their specific needs. Whether you're looking to develop an AI-powered chatbot, a computer vision system, or a sophisticated recommendation engine, the SLG AI Engine will have everything you need to get started. One of the critical features of the SLG AI Engine will be its ability to be repurposed for multiple applications. This means that developers can take the same AI tools and algorithms they used to build one application and use them to create something completely different without having to start from scratch. This will help to significantly reduce development time and make the SLG AI Engine an incredibly powerful and versatile tool.")
        }

//        Skill.values().forEach { skill ->
//            SkillBar(
//                title = skill.title,
//                index = skill.ordinal,
//                percentage = if (viewportEntered) skill.percentage else 0.percent,
//                animatedPercentage = if (viewportEntered) animatedPercentage[skill.ordinal] else 0
//            )
//        }
    }
}